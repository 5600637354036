import React from 'react';
import Layout from '../components/layout';
import ReactMarkdown from 'react-markdown';
import { graphql, StaticQuery } from 'gatsby';
import gfm from 'remark-gfm';
import 'github-markdown-css';
import rehypeRaw from 'rehype-raw';

const Index = () => {
  return (
    <StaticQuery query={graphql`
      query {
        allMarkdownRemark {
          nodes {
            html
            parent {
              ... on File {
                name
              }
            }
            rawMarkdownBody
          }
        }
      }

      `} render={(data) => {
      const markdownData = data.allMarkdownRemark.nodes.find((d) => d.parent.name === 'index');
      return (
        <Layout title={"Home"}>
          <div className={'markdown-body'} style={{ marginTop: '10px' }}>
            {markdownData === undefined
              ? null
              : (
                <ReactMarkdown
                  remarkPlugins={[gfm]}
                  rehypePlugins={[rehypeRaw]}
                  children={(markdownData.rawMarkdownBody)} />
              )}
          </div>
        </Layout>
      )
    }}
    >
    </StaticQuery>
  )
}

export default Index;
